import { withStyles } from '@bruitt/classnames'
import { ComponentProps, forwardRef, ReactNode } from 'react'

import { ButtonBase } from '@uikit/button-base'

import styles from './button.module.scss'

type ButtonProps = ComponentProps<typeof ButtonBase> & {
  startIcon?: ReactNode
  endIcon?: ReactNode
} & {
  variant?: 'black-link' | 'gray-link' | 'green' | 'green-link' | 'white-link' | 'neutral'
  fullWidth?: boolean
  ariaLabel?: string
}

const sx = withStyles(styles)

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      variant = 'green',
      fullWidth = false,
      startIcon,
      endIcon,
      children,
      className,
      ariaLabel,
      ...props
    },
    ref,
  ) => (
    <ButtonBase
      ref={ref}
      aria-label={ariaLabel}
      className={sx('root', className, { variant, fullWidth })}
      {...props}
    >
      {startIcon}
      {children}
      {endIcon}
    </ButtonBase>
  ),
)

Button.displayName = 'Button'
