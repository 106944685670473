import Link from 'next/link'
import { withStyles } from '@bruitt/classnames'
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ForwardedRef,
  MouseEvent,
  forwardRef,
} from 'react'

import styles from './button-base.module.scss'

const sx = withStyles(styles)

export interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  onClick?: () => void
  href: string
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  href?: never
}

type ButtonBaseProps = AnchorProps | ButtonProps

const isAnchor = (props: ButtonBaseProps): props is AnchorProps =>
  'href' in props && props.href !== undefined

const isButton = (props: ButtonBaseProps): props is ButtonProps =>
  'onClick' in props || props.type === 'submit'

export const ButtonBase = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonBaseProps>(
  (props, ref) => {
    const { className, children, ...rest } = props
    const buttonClassName = sx(styles.root, className)

    if (isAnchor(rest)) {
      return (
        <Link {...rest} className={buttonClassName} ref={ref as ForwardedRef<HTMLAnchorElement>}>
          {children}
        </Link>
      )
    } else if (isButton(rest)) {
      return (
        <button
          type="button"
          {...rest}
          className={buttonClassName}
          ref={ref as ForwardedRef<HTMLButtonElement>}
        >
          {children}
        </button>
      )
    }

    return <span className={buttonClassName}>{children}</span>
  },
)

ButtonBase.displayName = 'ButtonBase'
