'use client'

import { FC } from 'react'

import { Button } from '@uikit/button'
import { Svg404, Svg500 } from '@/uikit/svg/not-found'

import styles from './not-found-page.module.scss'

interface NotFoundPageProps {
  type?: '404' | '500'
}

export const NotFoundPage: FC<NotFoundPageProps> = ({ type = '404' }) => {
  return (
    <div className={styles.not_found}>
      <h1 className={styles.title}>
        {type === '404' ? 'Страница не найдена' : 'Что-то пошло не так...'}
      </h1>
      <div className={styles.main_img}>{type === '404' ? <Svg404 /> : <Svg500 />}</div>
      <div className={styles.btn}>
        <Button
          onClick={() => {
            location.href = '/'
          }}
        >
          Вернуться на главную
        </Button>
      </div>
    </div>
  )
}
