'use client'

import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

import '@/styles/globals.scss'

import { NotFoundPage } from '@/components/not-found-page'

export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    Sentry.captureException(error, {
      tags: {
        some: 'global Error',
      },
      level: 'warning',
    })
  }, [error])

  return (
    <html>
      <body>
        <NotFoundPage type="500" />
      </body>
    </html>
  )
}
