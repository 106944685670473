import { ReactElement, SVGProps } from 'react'
export const Svg404 = (props: SVGProps<SVGSVGElement>): ReactElement => (
  <svg viewBox="0 0 663 300" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#404_svg__a)" fill="#fff" stroke="#05A055" strokeWidth={2}>
      <path d="M554.063 278.473v1h60.669v-50.235H647v-54.802h-32.268V21.531h-41.456l-.301.385-119.537 153.272-.211.271v53.779h100.836v49.235Zm6.967-164.996v60.959h-46.839l46.839-60.959ZM419.084 182.486l.002-.005c8.389-17.023 12.571-36.368 12.571-58.011 0-21.64-4.181-40.864-12.571-57.645-8.382-17.01-19.861-30.35-34.442-39.989C370.048 16.942 353.474 12 334.953 12c-18.28 0-34.852 4.944-49.688 14.834-14.582 9.64-26.061 22.98-34.444 39.99-8.39 16.781-12.571 36.005-12.571 57.646 0 21.643 4.182 40.988 12.57 58.011l.003.005c8.383 16.768 19.864 29.984 34.444 39.622l.006.004c14.835 9.643 31.404 14.462 49.68 14.462 18.518 0 35.09-4.818 49.687-14.466 14.58-9.638 26.06-22.854 34.444-39.622Zm-66.103-107.51.008.007.009.007c5.47 4.757 9.786 11.327 12.919 19.76 3.127 8.421 4.704 18.321 4.704 29.72 0 11.4-1.577 21.3-4.704 29.72-3.133 8.434-7.449 15.004-12.919 19.761-5.197 4.487-11.197 6.721-18.045 6.721-7.098 0-13.347-2.239-18.787-6.729-5.464-4.755-9.775-11.318-12.905-19.741-2.887-8.425-4.342-18.33-4.342-29.732 0-11.401 1.455-21.306 4.342-29.73 3.132-8.43 7.446-14.995 12.914-19.75 5.444-4.734 11.69-7.088 18.778-7.088 6.834 0 12.829 2.347 18.028 7.073ZM116.837 278.473v1h60.668v-50.235h32.268v-54.802h-32.268V21.531h-41.456l-.3.385L16.212 175.188l-.212.271v53.779h100.837v49.235Zm6.967-164.996v60.959h-46.84l46.84-60.959Z" />
    </g>
    <defs>
      <filter
        id="404_svg__a"
        x={0}
        y={0}
        width={663}
        height={299.473}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={7.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.141176 0 0 0 0 0.129412 0 0 0 0 0.129412 0 0 0 0.15 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2690_16673" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_2690_16673" result="shape" />
      </filter>
    </defs>
  </svg>
)
